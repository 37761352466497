import React, {useState} from 'react';

import * as styles from './TextInput.module.scss';
import classNames from 'classnames';
import {FormError} from "../FormError";


export const TextInput = React.forwardRef(({
                                               label,
                                               onBlur,
                                               onFocus,
                                               multiLine,
                                               error,
                                               className,
                                               id,
                                               value,
                                               defaultValue,
                                               ...otherProps
                                           }, ref) => {
    const [stateId] = useState('id_' + Math.random());
    const [isFocused, setIsFocus] = useState(false);
    const realId = id ? id : stateId;
    const Component = multiLine ? 'textarea' : 'input';

    const errors = Array.isArray(error) ? error : (error ? [error] : []);
    const hasErrors = errors.length > 0;
    const realValue = value ? value : defaultValue;

    const classes = classNames(className,
        styles.root,
        realValue && styles.hasValue,
        isFocused && styles.isFocused,
        hasErrors && styles.isInvalid
    );

    return <div className={classes}>
        <Component id={realId}
                   className={styles.control}
                   value={value}
                   defaultValue={defaultValue}
                   data-is-invalid={hasErrors}
                   ref={ref}
                   onFocus={(e) => {
                       setIsFocus(true);
                       onFocus && onFocus(e);
                   }}
                   onBlur={(e) => {
                       setIsFocus(false);
                       onBlur && onBlur(e);
                   }}
                   {...otherProps}/>
        <label htmlFor={realId} className={styles.label}>{label}</label>
        {errors.map((error, i) => <FormError className={styles.error} key={i}>{error}</FormError>)}
    </div>;
})
