import React from 'react';
import {Button} from "../components/Button";
import '../sass/global.scss';

import * as styles from './styleguide.module.scss';
import {Typography} from "../components/Typography";
import {InlineSingleOption} from "../components/InlineSingleOption";
import {TextInput} from "../components/TextInput";
import {Checkbox} from "../components/Checkbox";

const COLOR_NAMES = [
    'red', 'lightGreen', 'yellow', 'lightBlue', 'blue', 'green', 'indigoRainbow', 'iris', 'lavenderIndigo', 'lightSilver', 'quickSilver', 'turquoise', 'ultramarineBlue', 'veryPeri', 'visidianGreen', 'royalBlue', 'urobilin', 'dullCyan', 'purpleHeart', 'palatinateBlue', 'mediumVermilion'
];


export default function Styleguide() {
    return <div className={styles.root}>
        <Section title="Typography">
            <Typography>
                <h1>H1 Section - You and your employees can have more time</h1>
                <h2>H2 Section - You and your employees can have more time</h2>
                <h3>H3 Section - You and your employees can have more time</h3>
                <h4>H4 Section - You and your employees can have more time</h4>

                <table>
                    <thead>
                    <tr>
                        <th>Title 1</th>
                        <th>Title 2</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Some content Some content Some content Some content Some content Some content Some content
                            Some content Some content Some content Some content Some content Some content
                        </td>
                        <td>Some content 2</td>
                    </tr>
                    </tbody>
                </table>
                <p>Ut varius ligula gravida massa fermentum, sit amet feugiat quam mattis. Ut tempus, tellus a luctus
                    consectetur, orci felis accumsan tellus, sed rhoncus nibh sem vitae tortor. Class aptent taciti
                    sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam finibus mollis felis, in
                    pulvinar
                    felis tincidunt sit amet. Curabitur consectetur semper elit, sit amet tempor felis rhoncus sed.
                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse
                    potenti. Sed enim lectus, vehicula vel ullamcorper eu, interdum ut enim. Fusce laoreet convallis
                    aliquam. Curabitur in hendrerit tortor. Suspendisse fringilla nulla sit amet risus faucibus, vitae
                    faucibus ligula congue. Nulla fringilla dolor vitae urna facilisis sagittis. Integer turpis nisi,
                    congue
                    in dapibus nec, mattis vitae nulla. Aenean pretium tellus eu neque dictum, ut scelerisque orci
                    sagittis.</p>

                <p>Vestibulum commodo lacus quis dui lobortis, non imperdiet metus malesuada. Integer scelerisque a eros
                    vitae maximus. Duis pellentesque blandit quam ac rhoncus. Donec molestie ex elit, eu bibendum eros
                    tristique et. Vestibulum ac tellus quis justo aliquam sodales. Class aptent taciti sociosqu ad
                    litora
                    torquent per conubia nostra, per inceptos himenaeos. Mauris erat purus, maximus sed risus nec,
                    pulvinar
                    interdum magna. Integer blandit dolor ullamcorper feugiat dapibus. Nam eget lacus sit amet neque
                    cursus
                    sagittis. Donec eleifend vehicula eros sit amet gravida. In ac enim mauris. Nullam venenatis commodo
                    nunc id interdum. Donec et quam urna. Fusce pharetra porttitor placerat. Orci varius natoque
                    penatibus
                    et magnis dis parturient montes, nascetur ridiculus mus.</p>
            </Typography>
        </Section>
        <Section title="Buttons">
            <Button>Click me</Button>
            <Button disabled component="button">Disabled button</Button>
        </Section>
        <Section title="Inline single option">
            <InlineSingleOption defaultValue="all" options={{
                all: 'All',
                banking: 'Banking',
                industry: 'Industry',
                it: 'IT',
                others: 'Others'
            }}/>
        </Section>
        <Section title="Form fields">
            <TextInput label="Full Name"/>

            <TextInput defaultValue="Testowa" label="Full Name" error="To imię jest brzydkie"/>
            <TextInput value="Sebastian" label="Full Name" disabled/>
        </Section>

        <Section title="Checkbox">
            <Checkbox>
                Default checkbox
            </Checkbox>

            <br/>
            <br/>
            <Checkbox defaultChecked>
                Checked checkbox
            </Checkbox>

            <br/>
            <br/>

            <Checkbox disabled>
                Disabled checkbox - unselected
            </Checkbox>

            <br/>
            <br/>

            <Checkbox disabled defaultChecked>
                Disabled checkbox - selected
            </Checkbox>
        </Section>
        <Section title="Colors">
            <div className={styles.colorsGrid}>
                {COLOR_NAMES.map((x, key) => {
                    return <div className={styles.color} key={key} data-color={x}>
                        <span className={styles.canvas}>Contrast</span>
                        <span className={styles.colorLabel}>{x}</span>
                    </div>;
                })}
            </div>
        </Section>
    </div>;
}

function Section({title, children}) {
    return <section className={styles.section}>
        <h1 className={styles.section_title}>{title}</h1>
        <div>
            {children}
        </div>
    </section>
}
