import * as styles from './Checkbox.module.scss';
import React from 'react';
import classNames from 'classnames';
import {FormError} from "../FormError";

export const Checkbox = React.forwardRef(({className, error, disabled, children, ...otherProps}, ref) => {
    const classes = classNames(className,
        styles.root,
        disabled && styles.isDisabled,
        error && styles.isError
    );
    return <label className={classes}>
        <input {...otherProps} disabled={disabled} type="checkbox" ref={ref}/>
        <span className={styles.content}>{children}</span>
        {error ? <FormError className={styles.error}>{error}</FormError> : undefined}
    </label>
});